<template>
	<div data-route>
		<page-header
			heading="Change your password"
		/>
		<div data-element="main">
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="submit"
			>
				<input-field
					name="Password"
					type="password"
					autocomplete="new-password"
					rules="required|min:8"
					v-model="credentials.password"
				/>
				<input-field
					name="Confirm password"
					type="password"
					autocomplete="new-password"
					rules="required|confirmed:password"
					v-model="credentials.passwordConfirm"
				/>
				<actions :actions="actions" />
			</validation-observer>
		</div>
	</div>
</template>

<script>

	import PageHeader          from '@/components/ui/PageHeader';
	import InputField          from '@/components/forms/InputField';
	import Actions             from '@/components/ui/Actions';
	import actionClick         from '@/mixins/actionClick';
	import invalidFormMessage  from '@/mixins/invalidFormMessage';
	import api                 from '@/services/api';

	export default {
		metaInfo: {
			title: 'Change your password'
		},
		components: {
			PageHeader,
			InputField,
			Actions
		},
		mixins: [actionClick, invalidFormMessage],
		data: () => ({
			credentials: {
				password:         '',
				passwordConfirm:  ''
			},
			actions: {
				primary: [
					{
						text: 'Change password',
						type: 'submit'
					},
					{
						text: 'Cancel',
						route: '/my-profile'
					}
				]
			}
		}),
		computed: {
			getBreadcrumbs () {
				return [
					{
						path: '/my-profile',
						text: 'My profile'
					},
					{
						path: '/my-profile/change-password',
						text: 'Change your password'
					}
				].filter(Boolean);
			}
		},
		created () {
			this.$store.commit('ui/setBreadcrumbs', {
				breadcrumbs: this.getBreadcrumbs
			});
			this.$store.commit('ui/setLoadingIsHidden');
		},
		methods: {
			async submit () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				const response = await api.auth.updateCurrentUserPassword({
					credentials: this.credentials
				});
				if (!response) {
					return;
				}
				// store the new JWT if it is present
				if (response.token) {
					await this.$store.dispatch('auth/setToken', {
						token: response.token
					});
				}
				await this.$router.push('/my-profile');
				this.$store.commit('ui/showNotification', {
					notification: 'Your password has been updated'
				});
			}
		}
	};

</script>
